import {
  BoundSelectField,
  Button,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { UppyFile } from "@uppy/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import { UppyUploader } from "src/components/UppyUploader";
import {
  LotOverviewPageLotDocumentFragment,
  LotOverviewPageLotFragment,
  SaveDocumentInput,
  useLotDocumentsQuery,
  useSaveDocumentsMutation,
  useSaveLotAcqSettlementStatementMutation,
  DocumentType,
  LotOverviewPageLotDocumentsLotFragment,
  InputMaybe,
  Maybe,
  useProcessLotAcqSettlementStatementOcrMutation,
} from "src/generated/graphql-types";
import { createLotAcqSettlementStatementUrl } from "src/RouteUrls";
import { queryResult } from "src/utils/queryResult";
import { safeEntries } from "src/utils/utils";

export type LotDocumentsTableProps = {
  lot: LotOverviewPageLotFragment;
};

export function LotDocumentsTable(props: LotDocumentsTableProps) {
  const { lot } = props;
  const [allowDocRedirect, setAllowDocRedirect] = useState(true);

  const query = useLotDocumentsQuery({ variables: { lotId: lot.id }, skip: !lot.id });

  const [saveDocuments] = useSaveDocumentsMutation();
  const [saveLotAcqSettlementStatement] = useSaveLotAcqSettlementStatementMutation();
  const [processLotAcqSettlementStatementOcr] = useProcessLotAcqSettlementStatementOcrMutation();

  // Once an asset has been uploaded, create a document with an asset
  async function handleOnFinish(file: UppyFile) {
    // This is to prevent multiple file uploads to redirect one after each other
    if (allowDocRedirect) {
      setAllowDocRedirect(false);
    }
    const saveDocumentsInput: [SaveDocumentInput] = [
      {
        parentId: lot.id,
        asset: {
          contentType: file.type,
          fileName: file.name,
          s3Key: file.meta.s3Key as string,
          sizeInBytes: file.size,
        },
        documentType: DocumentType.General,
      },
    ];
    await saveDocuments({ variables: { input: { documents: saveDocumentsInput } } });
    await query.refetch();
  }

  async function createLotAcqSettlementStatement(document: LotOverviewPageLotDocumentFragment) {
    // Create new settlement statement
    const newSettlementStatement = await saveLotAcqSettlementStatement({
      variables: { input: { documentId: document.id, lotId: lot.id } },
    });
    const settlementStatementId =
      newSettlementStatement.data?.saveLotAcqSettlementStatement.lotAcqSettlementStatement.id;
    if (settlementStatementId) {
      // Process the OCR for the new settlement statement
      await processLotAcqSettlementStatementOcr({ variables: { input: { id: settlementStatementId } } });
      // Open the new settlement statement in a new tab
      window.open(createLotAcqSettlementStatementUrl(document.id), "_blank", "noopener,noreferrer");
      // And refresh the page to show the new state
      await query.refetch();
    }
  }

  return queryResult(query, ({ lots }) => {
    return (
      <LotDocumentsForm
        lot={lots[0]}
        handleOnFinish={handleOnFinish}
        createLotAcqSettlementStatement={createLotAcqSettlementStatement}
        setAllowDocRedirect={setAllowDocRedirect}
      />
    );
  });
}

function LotDocumentsForm({
  lot,
  handleOnFinish,
  createLotAcqSettlementStatement,
  setAllowDocRedirect,
}: {
  lot: LotOverviewPageLotDocumentsLotFragment;
  handleOnFinish: (file: UppyFile) => void;
  createLotAcqSettlementStatement: (document: LotOverviewPageLotDocumentFragment) => void;
  setAllowDocRedirect: (allow: boolean) => void;
}) {
  const documents = lot.documents || [];
  const [saveDocuments] = useSaveDocumentsMutation();

  async function saveLotDocuments(formState: ObjectState<LotDocumentsFormInput>) {
    const changedDocuments = formState.changedValue.documents?.filter((doc) => doc.documentType !== undefined) || [];
    const documentInput: SaveDocumentInput[] = changedDocuments.map((doc) => {
      return { id: doc.id, documentType: doc.documentType };
    });
    await saveDocuments({
      variables: {
        input: {
          documents: documentInput,
        },
      },
    });
  }

  const formState = useFormState({
    config: formConfig,
    init: {
      input: mapLotDocumentsLotToForm(lot),
    },
    autoSave: saveLotDocuments,
  });

  return (
    <>
      <UppyUploader onFinish={handleOnFinish} onComplete={() => setAllowDocRedirect(true)} />

      <GridTable
        id="lotPartitionsTable"
        rowStyles={rowStyles}
        columns={createLotDocumentColumns(createLotAcqSettlementStatement, formState)}
        rows={createLotDocumentRows(documents)}
        fallbackMessage="Documents for this Lot will show here."
      />
    </>
  );
}

export type LotDocumentsFormInput = {
  id: InputMaybe<string>;
  documents: {
    id: InputMaybe<string>;
    documentType: InputMaybe<DocumentType>;
  }[];
};

export const formConfig: ObjectConfig<LotDocumentsFormInput> = {
  id: { type: "value" },
  documents: {
    type: "list",
    config: {
      id: { type: "value" },
      documentType: { type: "value" },
    },
  },
};

function mapLotDocumentsLotToForm(lot: LotOverviewPageLotDocumentsLotFragment): LotDocumentsFormInput {
  return {
    id: lot.id,
    documents: mapLotDocumentsToForm(lot.documents),
  };
}

export function mapLotDocumentsToForm(documents: Maybe<LotOverviewPageLotDocumentFragment[]>) {
  const docs = documents?.map((doc) => {
    return {
      id: doc.id,
      documentType: doc.documentType.code,
    };
  });
  return docs || [];
}

type Row = SimpleHeaderAndData<LotOverviewPageLotDocumentFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createLotDocumentRows(documents: LotOverviewPageLotDocumentFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(documents);
}

function createLotDocumentColumns(
  createLotAcqSettlementStatement: (arg0: LotOverviewPageLotDocumentFragment) => void,
  formState: ObjectState<LotDocumentsFormInput>,
): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Document ID",
    data: ({ id }) => id,
    w: "100px",
  });

  const nameColumn = column<Row>({
    header: () => "Document Name",
    data: ({ asset }) => asset.fileName,
    w: "300px",
  });

  const downloadColumn = column<Row>({
    header: () => "",
    data: ({ asset }) => {
      return (
        <Button
          label="Download"
          variant="tertiary"
          onClick={() => {
            // Open the download url in a new tab
            window.open(asset.downloadUrl, "_blank", "noopener,noreferrer");
          }}
        />
      );
    },
  });

  console.log("safeEntries(DocumentType)", safeEntries(DocumentType));
  const typeColumn = column<Row>({
    header: () => "Type",
    data: (row) => {
      const formStateField = formState.documents.rows.find((doc) => doc.id.value === row.id);
      console.log("formStateField", formStateField);
      return {
        value: () => row.documentType,
        content: () => (
          <BoundSelectField
            field={formStateField!.documentType}
            options={safeEntries(DocumentType)}
            getOptionValue={([, code]) => code}
            getOptionLabel={([name]) => name}
          />
        ),
      };
    },
    w: "300px",
  });

  const actionButtonColumn = column<Row>({
    header: () => "",
    data: (document) => {
      const create = document.canCreateLotAcqSettlementStatement.allowed;
      if (create) {
        return (
          <Button
            label="Create Settlement Statement From Document"
            variant="secondary"
            onClick={async () => {
              await createLotAcqSettlementStatement(document);
            }}
          />
        );
      }
      const view = document.canViewLotAcqSettlementStatement.allowed;
      if (view) {
        return <Link to={createLotAcqSettlementStatementUrl(document.id)}>View Settlement Statement</Link>;
      }
      return <div>No actions available</div>;
    },
  });

  return [idColumn, nameColumn, downloadColumn, typeColumn, actionButtonColumn];
}
